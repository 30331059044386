<template>
  <div class="app-container">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span
            >家
          </div>
        </div>
        <div class="each" @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>异常</div>
            <a
              ><span>{{ info.countErr }}</span
              >家</a
            >
          </div>
        </div>
        <div class="each" @click="getNewListTotal(4)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div class="each_text">
            <div>待质检</div>
            <a>
              <span>{{ info.countDqrZj }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(3)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div class="each_text">
            <div>已质检</div>
            <a>
              <span>{{ info.countYqrZj }}</span
              >家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period
          v-model:period="listQuery.period"
          :clearable="false"
          style="width: 100px"
          @change="getNewList"
        ></qzf-search-period>
      </div>
    </div>
    <div class="content_select">
      <div class="left_select">
        <el-button
          icon="RefreshRight"
          size="small"
          @click="getList()"
          style="margin-right: 5px"
        ></el-button>
        <el-input
          placeholder="请输入企业名称"
          v-model="listQuery.name"
          style="width: 160px"
          size="small"
          @keyup.enter="getList"
          clearable
        /><el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin: 11px 0px 0px 15px; width: 400px"
            label-width="110px"
            size="small"
          >
            <el-form-item label="纳税人类型：">
              <selecttaxtype
                v-model:type="listQuery.type"
                class="select_width"
              ></selecttaxtype>
            </el-form-item>
            <el-form-item label="税局：">
              <selectcity
                v-model:citys="listQuery.districtCode"
                class="select_width"
              ></selectcity>
            </el-form-item>
            <el-form-item label="结账状态：">
              <el-radio-group v-model="listQuery.jzStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结账</el-radio>
                <el-radio :label="2">未结账</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="质检确认状态：">
              <autoHomeSelect
                v-model:modelValue="listQuery.zjStatus"
                placeholder="请选择质检确认状态"
                type="zj"
              />
            </el-form-item>
          </el-form>
        </search>
        <el-radio-group
          v-model="listQuery.djIsNot0"
          style="margin-left: 5px"
          size="small"
          @change="getList"
        >
          <el-radio-button :label="0">全部</el-radio-button>
          <el-radio-button :label="1">有单据</el-radio-button>
          <el-radio-button :label="2">无单据</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="listQuery.vouIsNot0"
          style="margin-left: 5px"
          size="small"
          @change="getList"
        >
          <el-radio-button :label="0">全部</el-radio-button>
          <el-radio-button :label="1">有凭证</el-radio-button>
          <el-radio-button :label="2">无凭证</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="listQuery.taxIsNot0"
          style="margin-left: 5px"
          size="small"
          @change="getList"
        >
          <el-radio-button :label="0">全部</el-radio-button>
          <el-radio-button :label="1">有税款</el-radio-button>
          <el-radio-button :label="2">无税款</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button
          @click="skipAll()"
          type="success"
          size="small"
          plain
          icon="Right"
          >跳过
        </el-button>
        <el-button
          @click="confirmAll()"
          type="primary"
          size="small"
          plain
          icon="Checked"
          :disabled="!$buttonStatus('zdjz_zjqr')"
          >质检确认
        </el-button>
        <el-button
          @click="confirmAllRg()"
          type="primary"
          size="small"
          plain
          icon="Checked"
          >人工确认</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        @selection-change="handleSelectionChange"
        stripe
        @sort-change="sortChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column
          label="编号"
          align="center"
          width="80"
          fixed="left"
          prop="sort"
          sortable="custom"
        >
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="企业名称"
          min-width="280"
          fixed="left"
        >
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false" />
          </template>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="税局"
          width="65"
          align="center"
        >
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="djCount"
          label="单据数量"
          align="right"
          min-width="120"
          sortable
        />
        <el-table-column
          prop="pzCount"
          label="凭证数量"
          align="right"
          min-width="120"
          sortable
        />
        <el-table-column
          prop="taxAmount"
          label="总税金"
          align="right"
          min-width="90"
          sortable
        >
          <template #default="scope">
            {{ scope.row.taxAmount ? scope.row.taxAmount : "0" }}
          </template>
        </el-table-column>
        <el-table-column prop="zjErr" label="风险评测" min-width="120">
          <template #default="scope">
            <el-tooltip
              placement="top"
              :content="scope.row.zjErr"
              effect="dark"
            >
              {{ scope.row.zjErr }}
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="质检状态" width="90">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$filterAutoStatusIcon(scope.row.zj_status)"></i>
              <span>{{
                $filterAutoStatusText(scope.row.zj_status, "zj")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="质检人" width="90">
          <template #default="scope">
            <div v-if="scope.row.zj_status == 3">
              {{
                scope.row.zjConfirmUserName
                  ? scope.row.zjConfirmUserName
                  : "系统自动"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="人工确认" width="90">
          <template #default="scope">
            <div class="item_icon">
              <div>
                <i :class="$homeConfirmIcon(scope.row.handQr)"></i>
                <span>{{ $homeConfirmStatus(scope.row.handQr) }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="确认人" width="90">
          <template #default="scope">
            <div v-if="scope.row.handQr">
              {{ scope.row.handQrUser }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template #default="scope">
            <el-button
              size="small"
              link
              :disabled="
                scope.row.zj_status != 2 || !$buttonStatus('zdjz_zjqr')
              "
              @click="confirm(scope.row)"
              icon="Checked"
            >
              质检确认
            </el-button>
            <el-button
              size="small"
              link
              :disabled="!$buttonStatus('zdjz_zjqr')"
              @click="confirmRg(scope.row)"
              icon="Checked"
            >
              人工确认
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
  <abnormalCom ref="abnormalCom" />
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import {
  confirmAutomatic,
  automaticStatTax,
  companyAutoZjList,
  batchJumpAutomatic,
  handSure,
} from "@/api/automatic";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import abnormalCom from "./components/abnormalCom.vue";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import autoHomeSelect from "./components/autoHomeSelect.vue";
export default {
  name: "taxConfirm",
  components: {
    selectcity,
    selecttaxtype,
    abnormalCom,
    TableSortCell,
    TagNameCopy,
    autoHomeSelect,
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        period: currentAccountPeriod(),
        type: "",
        autoMatic: 1,
        djIsNot0: 0,
        vouIsNot0: 0,
        taxIsNot0: 0,
      },
      loading: false,
      tableData: [],
      total: 0,
      selects: [],
      dialogVisible: false,
      taxInfo: {},
      info: {},
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(260);
  },
  mounted() {
    this.getList();
    this.getInfo();
  },

  methods: {
    getList() {
      this.loading = true;
      companyAutoZjList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    getInfo() {
      automaticStatTax(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.info = res.data.data;
        }
      });
    },
    getNewList() {
      this.getList();
      this.getInfo();
    },
    getNewListTotal(e) {
      this.listQuery.taxStatusTotal = e ? e : null;
      this.getNewList();
    },
    getWjz() {
      this.$refs.abnormalCom.init(this.listQuery.period);
    },
    // 筛选打印
    handleSelectionChange(val) {
      this.selects = val;
    },
    //批量跳过
    skipAll() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let fail = this.selects.find((v) => v.zj_status != 2);
      if (fail) {
        this.$qzfMessage(`${fail.name}不是待质检状态不可跳过，请重新选择！`, 1);
        return;
      }
      this.$confirm(`确定要批量跳过质检确认吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        this.selects.map((v) => {
          ids.push(v.comId);
        });
        let param = {
          comIds: ids,
          period: this.listQuery.period,
          type: "zj",
        };
        batchJumpAutomatic(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("操作成功");
            this.getList();
            this.$bus.emit("confirmStatusUpdate");
          }
        });
      });
    },
    //人工确认
    confirmAllRg() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.$confirm("确定批量人工确认吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          comIds: this.selects.map((v) => {
            return v.comId;
          }),
          period: this.listQuery.period,
          handStatus: 1,
        };
        handSure(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("已确认");
            this.getList();
          }
        });
      });
    },
    confirmRg(row) {
      this.$confirm("确定人工确认吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          comIds: [row.comId],
          period: this.listQuery.period,
          handStatus: 1,
        };
        handSure(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("已确认");
            this.getList();
          }
        });
      });
    },
    //确认
    confirm(row) {
      this.$confirm(`确定要质检确认吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        confirmAutomatic([
          {
            comId: row.comId,
            period: this.listQuery.period,
            type: "zj",
          },
        ]).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("操作成功");
            this.getList();
            this.$bus.emit("confirmStatusUpdate");
          }
        });
      });
    },
    //批量确认
    confirmAll() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let str = "";
      this.selects.map((v) => {
        if (v.zj_status != 2) {
          str = v.name + "不是待质检状态不可确认，请重新选择！";
        }
      });
      if (str) {
        this.$qzfMessage(str, 1);
        return;
      }
      this.$confirm(`确定要批量质检确认吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        this.selects.map((v) => {
          ids.push({
            comId: v.comId,
            period: this.listQuery.period,
            type: "zj",
          });
        });
        confirmAutomatic(ids).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("操作成功");
            this.getList();
            this.$bus.emit("confirmStatusUpdate");
          }
        });
      });
    },
    // 表格排序
    sortChange(data) {
      this.listQuery.descType = data.column.label;
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList();
    },
    //重置
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        period: currentAccountPeriod(),
        type: "",
        autoMatic: 1,
        djIsNot0: 0,
        vouIsNot0: 0,
        taxIsNot0: 0,
      };
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.content_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  .left_select {
    display: flex;
    align-items: center;
  }
}
.item_icon {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #333;
  i {
    font-size: 13px;
    margin-right: 2px;
  }
}
.select_width {
  width: 140px !important;
}
</style>
