<template>
  <!-- <el-select @change="changeSelect" size="mini" clearable filterable v-model="id" placeholder="请选择11">
    <el-option-group
      v-for="group in options"
      :key="group.label"
      :label="group.label">
      <el-option
        v-for="item in group.options"
        :key="item.sign"
        :label="item.name"
        :value="item.sign">
      </el-option>
    </el-option-group>
  </el-select> -->

  <!-- <el-cascader ref="cascaderRef" placeholder="请选择业务类型" :class="{boxShadowNone:borderNone}" clearable filterable v-model="id" :props="cascaderProps" :options="options" :disabled="disabledIn" style="width:100%" size="small">
    <template #default="{ node, data }">
      <span v-if="data.coding" style="color:var(--themeColor,#17a2b8)"> ({{ data.coding}}) </span>
      <span>{{ data.name }}</span>
      <span v-if="data.unit" style="color:var(--themeColor,#17a2b8)"> ({{ data.unit}}) </span>
    </template>
  </el-cascader> -->

  <VirtualCascader v-model="id" labelKey="name" valueKey="sign" childrenKey="items" :options="options" />


  <!-- <add-inventory @success="addInventorySuccess" ref="addInventory" :comId="comId" :from="from"></add-inventory> -->
  <add-subject @success="addSubjectSuccess" ref="addSubject" :comId="comId"></add-subject>
  <!-- <addAssets @success="addAssetSuccess" :type="this.typexsjx" ref="addAssets" :tyname="'xzjx'" title="关联资产" :comId="comId" :period="period"></addAssets> -->
   <!-- <business-type v-model:businessId="a" v-model:itemId="b" v-model:fzhsId="c" type="in"></business-type> -->
</template>
<script>
import addAssets from "@/views/input/assets/components/addAssets.vue"
import VirtualCascader from './VirtualCascader'

export default {
  props:{
    type: {//in 进项
      type: String,
      default: ""
    },
    businessId: {//sign 第一个值 相当于itemType
      type: Number,
      default: 0
    },
    itemId: {//sign 第二个值 可为存货id 或者 subjectId
      type: Number,
      default: 0
    },
    fzhsId: {//sign 第三个值 相当于辅助核算id
      type: Number,
      default: 0
    },
    itemType: {//
      type: String,
      default: ""
    },
    name: {//默认新增存货名称
      type: String,
      default: ""
    },
    spec: {//默认新增存货型号
      type: String,
      default: ""
    },
    unit: {//默认新增存货单位
      type: String,
      default: ""
    },
    disabledIn:{
      type:Boolean,
      default:false
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    direction: {//支出收入 （银行）
      type: String,
      default: ""
    },
    add:{
      type:Boolean,
      default:false
    },

    // 关联资产的
    assetsAmount: {
      type: Number,
      default: 0
    },
    assetsName:{
      type:String,
      default:""
    },
    assetsDate:{
      type:String,
      default:""
    },
    assetsCount:{
      type:Number,
      default:0
    },
    comId: {
      type: Number,
      default: 0
    },
    period:{
      type:String,
      default:""
    },
    from:{
      type:String,
      default:""
    }
  },
  components:{ addAssets, VirtualCascader },
  computed: {
    options(){
      let data = []
      if(this.type == 'in'){
        data = this.$store.getters["commons/businessType"].in
      }else if(this.type == 'bank'){
        let datas = this.$store.getters["commons/businessType"].bank
        if(this.add){
          datas = this.$store.getters["commons/businessType"].bank2
        }
        if(this.direction == 'in'){//入账
          data = datas?.filter(v=>{return v.szbz == 1})
        }else if(this.direction == 'out'){//出账
          data = datas?.filter(v=>{return v.szbz != 1})
        }else{//入出都存在
          data = datas || []
        }
      }else if(this.type == 'cash'){
        data = this.$store.getters["commons/businessType"].cash
      }else if(this.type == 'bill'){
        data = this.$store.getters["commons/businessType"].bill
      }
      return data
    },
    id: {
      get() {
        if(!this.businessId || !this.itemId*1){
          return []
        }
        return [String(this.businessId), this.businessId + "-" + this.itemId + "-" + this.fzhsId]
      },
      set(val) {
        if(!val?.length){
          this.$emit("update:businessId", 0)
          this.$emit("update:itemId", 0)
          this.$emit("update:fzhsId", 0)
          this.$emit("success", [0, "0-0"])
          return
        }
        this.bid = val[0]
        this.addId = val[0] * 1
        let businessTypeInfo = this.findInfo(this.addId)
        if(val[1] == 'addInventory'){
          let data = {
            id: undefined,
            name: this.name,
            spec: this.spec,
            unit: this.unit,
            type: this.addId,
          }
          this.$refs.addInventory.init(data)
        }else if(val[1] == 'addSubject'){
          let data = {
            id: undefined,
            name: this.name,
            type: 0,
          }
          let useCodes = null
          if(businessTypeInfo){
            useCodes = businessTypeInfo.useCodes
          }
          this.$refs.addSubject.init(data, useCodes)
        } else if(val[1] == 'addAssage'){
          // this.assetsDate = this.$parseTime(this.assetsDate, "{y}-{m}-{d}")
          //14 固定资产
          //16 无形资产
          //17 待摊费用

          if(this.bid == 14) {
            this.typexsjx = 1
          }else if(this.bid == 16) {
            this.typexsjx = 2
          }else if(this.bid == 17) {
            this.typexsjx = 3
          }
          let param = {
            name:this.assetsName,
            originalValue:this.assetsAmount,
            unit:this.unit,
            type:this.typexsjx,
            transDate:this.$parseTime(this.assetsDate, "{y}-{m}-{d}"),
            count:this.assetsCount
          }
          this.$refs.addAssets.openDialog(param,this.typexsjx,"glzc")
        }
        if(val.length > 1){
          this.$emit("update:businessId", val[0] * 1)
          this.$emit("update:itemId", val[1].split('-')[1] * 1)
          this.$emit("update:fzhsId", val[1].split('-')[2] * 1)
          //存货的 双向绑定一下 型号 单位
          if(val[0] * 1 <= 11 || val[0] * 1 == 20 || val[0] * 1 == 21 || val[0] * 1 == 22){
            let info = {}
            this.options.map(v=>{
              if(v.dataType == 'inventory'){
                v.items.map(z=>{
                  if(z.sign == val[1]){
                    info = z
                  }
                })
              }
            })
            this.$emit("update:spec", info.spec)
            this.$emit("update:unit", info.unit)
            this.$emit("update:itemType", "inventory")
          }else{
            this.$emit("update:itemType", "item")
          }
        }
        if(businessTypeInfo){
          this.$emit("update:szbz", businessTypeInfo.szbz)
          this.$emit("update:businessTypeName", businessTypeInfo.name)
        }
        this.$emit("success2", businessTypeInfo.name)
        this.$emit("success", val)
      }
      
    }
  },
  data () {
    return {
      bid: "",
      cascaderProps: {
        "label": "name",
        "value": "sign",
        "children": "items",
      },
      typexsjx: 1
    }
  },
  methods: {
    getCheckedNodes() {
      return this.$refs.cascaderRef.getCheckedNodes()
    },
    //关联资产回调
    addAssetSuccess(sign){
      this.id = [this.bid, this.bid + '-' + sign]
    },
    findInfo(val){
      return this.options.find(v => v.id == val)
    },
    addInventorySuccess(e){
      this.id = [this.bid, this.bid + "-" + String(e.id) + "-0"]
    },
    addSubjectSuccess(e){
      this.id = [this.bid, this.bid + "-" + e.sign]
    },
  }
}
</script>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
</style>