<template>
  <div class="app-container">
    <section class="top_btns clearfix">
      <div style="float:left">
        <span style="font-size:13px">凭证状态：</span>
        <el-select size="small" v-model="listQuery.voucher" @change="getList" style="margin:0;width:100px">
          <el-option label="全部" :value="9"></el-option>
          <el-option label="已生成凭证" :value="1"></el-option>
          <el-option label="未生成凭证" :value="2"></el-option>
        </el-select>
      </div>
      <div class="piliang-container">
        <!-- <qzf-video vid="4467be5374188653c2bd063f2678059e_4"></qzf-video> -->

        <!-- <span style="color:#F15A24;font-size:15px;">余额:</span>
        <span style="color:#F15A24;font-size:15px;margin-right:10px">{{cashAmount}}</span> -->
        <!-- <qzf-button jz="false" button_code="zj_xj_tj" size='small' type="primary" @success="handleAdd"    >
          <el-icon><CirclePlus /></el-icon><span  > 添加</span>
        </qzf-button> -->
        <el-dropdown split-button type="primary" @click="handleAdd" size="small" style="margin-right:10px" :hide-on-click="false" v-if="$buttonStatus('zj_pj_tj')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu class="xxx">
              <el-dropdown-item>
                <span size="small" @click="openCom">票据导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button button_code="zj_pj_bc" size='small' type="success" @success="saveAll" :loading="saveLoading">
          <el-icon><Finished /></el-icon> 批量保存
        </qzf-button>
        <qzf-button button_code="zj_pj_sc" plain size="small" type="danger" @success="delAll()"   >
          <el-icon><Delete /></el-icon><span  > 删除</span>
        </qzf-button>
       
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px" v-if="$buttonStatus('zj_pj_yjscpz')">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a">
                <span>一键生成凭证</span>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <span>导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </section>
    <div :style="{height: contentStyleObj}">
      <vxe-table v-loading="loading" :data="list" :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }" height="auto" style="width: 100%"
        border stripe auto-resize size="mini" @checkbox-all="handleSelectionChange"
        @checkbox-change="handleSelectionChange" :column-config="{ resizable: true }" @sort-change="sortChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="checkbox" type="checkbox" width="50">
        </vxe-column>
        <vxe-column title="日期" field="transDate" align="center" width="150">
          <template #default="scope">
            <el-date-picker v-if="scope.row.edit" style="width: 100%" v-model="scope.row.transDate" type="date" placeholder="选择日期" size="small"></el-date-picker>
            <span v-else>{{ scope.row.showTransDate }}</span>
          </template>
        </vxe-column>
        <vxe-column title="票号" field="remark" align="center" min-width="150">
          <template #default="scope">
            <el-input v-if="scope.row.edit" v-model="scope.row.remark" size="small" />
            <span v-else>{{ scope.row.remark }}</span>
          </template>
        </vxe-column>
        <vxe-column title='业务类型' field="businessTypeName" align="center" min-width="320">
          <template #default="scope">
            <div class="block">
              <business-type ref="businesType" v-if="scope.row.edit" v-model:businessId="scope.row.businessTypeId" v-model:itemId="scope.row.subjectId" v-model:fzhsId="scope.row.fzhsId" v-model:szbz="type" v-model:businessTypeName="scope.row.businessTypeName" type="bill" @success2="changeBusiness2" @success="changeBusiness(scope.row,scope.row)"></business-type>
              <span v-else>{{ scope.row.businessTypeName }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column title="票据" field="code" align="center" min-width="220">
          <template #default="scope">
            <div class="block">
              <subject-list v-if="scope.row.edit" v-model:subjectId="scope.row.billSubjectId" v-model:fzhsItemId="scope.row.billFzhsId" :codes="$findCode(code)"></subject-list>
              <span v-else>{{ scope.row.subjectName }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column title="金额" field="amount" align="center" min-width="150">
          <template #default="scope">
            <qzf-input v-if="scope.row.edit" v-model:num="scope.row.amount" size="small" />
            <span v-else>{{ scope.row.amount }}</span>
          </template>
        </vxe-column>
        <vxe-column title="备注说明" field="summary" align="center" min-width="150">
          <template #default="scope" >
            <el-input v-if="scope.row.edit" v-model="scope.row.summary" size="small" />
            <span v-else>{{ scope.row.summary }}</span>
          </template>
        </vxe-column>
        <vxe-column title="凭证" field="voucherNo" align="center" min-width="120">
          <template #default="scope" >
            <span v-if="scope.row.voucherNo" @click="getHandlePingzheng(scope.row.voucherId)" style="color:#39b0d2;text-decoration:underline;cursor:pointer">{{ scope.row.voucherNo }}</span>
            <span v-else-if="$buttonStatus('zj_pj_yjscpz')" @click="showVoucher(scope.row)" style="color:#39b0d2;text-decoration:underline;cursor:pointer">凭证预览</span>
          </template>
        </vxe-column>
        <vxe-column title="操作" field="opera" align="center" class-name="top_right_btns" min-width="120">
          <template #default="scope">
            <!-- <el-button v-if="$buttonStatus('zj_pj_tj') && scope.row.edit" type="primary" link @click="handleSave(scope.row)">保存</el-button>
            <el-button v-if="$buttonStatus('zj_pj_bj') && !scope.row.edit" type="primary" link @click="handleEdit(scope.row)">编辑</el-button>
            <el-button v-if="$buttonStatus('zj_pj_sc')" type="primary" link @click="handleDelete(scope.row, scope.rowIndex)">删除</el-button> -->
            <el-tooltip content="保存" placement="top" v-if="$buttonStatus('zj_pj_tj')" effect="dark">
              <span>
                <i class="iconfont icon-baocun" @click="handleSave(scope.row)" v-if="scope.row.edit"></i>
              </span>
            </el-tooltip>
            <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('zj_pj_bj')" effect="dark">
              <span>
                <i class="iconfont icon-bianji" @click="handleEdit(scope.row)" v-if="!scope.row.edit"></i>
              </span>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" v-if="$buttonStatus('zj_pj_sc')" effect="dark">
              <span>
                <i class="iconfont icon-shanchu" @click="() => handleDelete(scope.row,scope.rowIndex)"></i>
              </span>
            </el-tooltip>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    <voucher-show ref="voucherShow" from="5" @success="getList"></voucher-show>

    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="票据" @success2="getBusiness"></qzf-import-back>

  </div>
</template>

<script>
import { saveBill , delBill , billList } from "@/api/bill"
import { getPeriodTime } from "@/utils/fun";
import { oneKeyVoucher } from '@/api/voucher'
import {wbVoucherFxOther} from '@/api/export'
// import ChooseBussiness from './ChooseBussiness.vue'
import { dayjs } from "element-plus";

export default {
  name:'bill',
  components: {
    // ChooseBussiness,
  },
  data() {
    return {
      cashAmount: 0,
      index: "", //大项
      $index: "", //小项
      periodAmount: "",
      fromInput: null,
      list: [], // 现金列表
      //联级选择器的业务数据
      options: [],
      listQuery: {
        page: 1,
        limit: 20,
        voucher:9,
      },
      total:0,
      code: ['code1121','code2201','code2202','code1122'],
      allDelSelect:[],
      allSaveSelect:[],
      isLoading:false,
      changeSummary:'',
      comId: this.$store.getters['user/comInfo'].comId * 1,
      saveLoading:false
    }
  },
  created() {
    this.getList()
    this.contentStyleObj = this.$getHeight(200)
  },
  methods:{
    handleAdd() {
      if(this.$checkSettleStatus()) return
      this.list = [
        {
          amount: 0,
          transDate: getPeriodTime(),
          edit: true,
          remark: ""
        },
        ...this.list
      ];
    },
    handleSave(item){
      if(this.$checkSettleStatus()) return
      saveBill([item]).then(res=>{
        if(res.data.msg == "success"){
          item.edit = false
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')

          this.getList();
        }
      })
    },
    handleEdit(item) {
      if(this.$checkSettleStatus()) return
      item.edit = true
    },
    handleDelete(item, index) {
      if(this.$checkSettleStatus()) return
      if(!item.id){
        this.list.splice(index, 1);
        return
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delBill([{id:item.id}]).then(res => {
          if(res.data.msg == 'success'){
            this.$qzfMessage("删除成功")
            this.getList();
            this.$bus.emit('voucherUpdate')
          }
        });
      })
    },
    getList(){
      this.allSaveSelect = []
      this.allDelSelect = []
      billList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list.map(item => {
            return {
              ...item,
              showTransDate: dayjs(item.transDate).format('YYYY-MM-DD'),
            }
          })
          this.total = res.data.data.total
        }else{
          this.list = []
          this.total = 0
        }
      })
      this.$store.dispatch("commons/getSubjects")
    },
    // 多选
    handleSelectionChange(data) {
      //多选选完之后在这
      let delArr = data.records.map(item => {
        return {id: item.id}
      })
      let saveArr = data.records
      this.allDelSelect = delArr;
      this.allSaveSelect = saveArr
    },
    delAll(){
      if(this.allDelSelect.length == 0){
        this.$qzfMessage("请至少选择一个",1)
        return
      }
      this.$confirm(`确认删除${this.allDelSelect.length}条票据么? `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      this.isLoading = true
        delBill(this.allDelSelect).then(res => {
          this.isLoading = false
          if(res.data.msg != 'success'){
            return
          }
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.$bus.emit('voucherUpdate')
          this.getList();
        });
      })
    },
    saveAll(){
      if(this.allSaveSelect.length == 0){
        this.$qzfMessage("请至少选择一个",1)
        return
      }
      this.saveLoading = true
      saveBill(this.allSaveSelect).then(res=>{
        this.saveLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')
          this.getList();
        }
      })
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/getSubjects', this.comId)
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    handleCommand(command) {
      if(command == 'a'){
        if(this.$checkSettleStatus()) return
        if(this.allDelSelect.length == 0){
          this.$qzfMessage("请选择至少一条数据",1)
          return
        }
        let invoiceArray = []
        this.allDelSelect.map(v => {
          invoiceArray.push(v.id)
        })
        this.isLoading = true
        oneKeyVoucher({type:'bill',ids:invoiceArray}).then(res=>{
          this.isLoading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      }else if(command == 'b'){
        let ids = []
        this.allDelSelect.map(v => {
          ids.push(v.id)
        })
        let param = {
          outType:"excel",
          exportType:"bill",
          query:{
            voucher:this.listQuery.voucher,
            ids:ids
          }
        }
        wbVoucherFxOther(param).then(res=>{
          if(res.data.msg == "success"){
            window.open(res.data.data.url)
          }
        })
      }
    },
    // 票据导入
    openCom(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBck.handImport()
    },
    getBusiness(){
     this.$store.dispatch("commons/getBusinessType")
      this.getList()
    },
    changeBusiness(val,item){
      if(!val.summary ){
        val.summary = this.changeSummary
      }
    },
    changeBusiness2(val){
      this.changeSummary = val
    },
  }
}
</script>

<style lang="scss" scoped>
.piliang-container {
  float: right;
}
.bank-table-header {
  background: #eee;
  position: relative;
  border: 1px solid #c2c2c2;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
  > div {
    float: left;
    border-right: 1px solid #c2c2c2;
    text-align: center;
    box-sizing: border-box;
    width: calc((100%) / 6);
  }
   > div:last-child{
    border-right: 0;
  }
  input {
    position: absolute;
    zoom: 120%;
    left: 5px;
    top: 50%;
    transform: translateY(-50%) !important;
  }
}
.top_right_btns {
  float: right;
  margin-right: 15px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 18px;
    line-height: 24px;
  }
}
 
.pagination{
  text-align: right;
  margin-right: 26px;
  margin-top: 16px;
}
.top_btns{
  margin-bottom :10px;
}
</style>