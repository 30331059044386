<script setup>
import { ref, defineProps, watch, shallowRef, nextTick, defineEmits, computed } from 'vue'
// import { VirtList } from 'vue-virt-list'
import { ArrowRight } from '@element-plus/icons-vue'
import { ElIcon } from 'element-plus'
import InfiniteList from 'vue3-infinite-list'
// import { VirtualScroll } from 'vue3-virtual-scroll'
// import 'vue3-virtual-scroll/dist/style.css'

const emit = defineEmits(['change', 'update:modelValue'])
const props = defineProps({
  style: {
    type: Object,
    default: () => ({})
  },
  popperVisible: {
    type: Boolean,
    default: false
  },
  onDestroy: {
    type: Function
  },
  options: {
    type: Array,
    default: () => []
  },
  labelKey: {
    type: String,
    default: 'label'
  },
  valueKey: {
    type: String,
    default: 'value'
  },
  childrenKey: {
    type: String,
    default: 'children'
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  searchValue: {
    type: String,
    default: undefined,
  }
})
const list = shallowRef(props.options)
const nextList = shallowRef([])
const searchList = shallowRef([])
const current = ref()
const currentInner = ref()
const currentSearchOption = ref()
const firstIndex = ref()
const secondIndex = ref()
hasModelValue()
const firstMenuRef = ref()
const nextStyle = ref({})
const refreshFirst = ref(true)

watch(nextList, () => {
  calNextPosi()
})

watch(() => props.popperVisible, (val) => {
  if (!val) {
    props.onDestroy()
  }
})

function calSearchShowOptions(val) {
  refreshFirst.value = false
  nextList.value = []
  searchList.value = []
  nextTick(() => {
    searchList.value = val.reduce((acc, f) => {
      acc.push(...f[props.childrenKey].map(s => {
        return {
          ...s,
          parent: f,
          parentValue: f[props.valueKey],
          parentLabel: f[props.labelKey],
          [props.labelKey]: `${s[props.labelKey]}`,
        }
      }))

      return acc
    }, [])

    if (props.modelValue?.length) {
      const o = searchList.value.find(item => item[props.valueKey] === props.modelValue[1])
      if (o) {
        currentSearchOption.value = o
      }
    }
  })
}

function optionsChange(val) {
  if (props.searchValue.value) {
    calSearchShowOptions(val)
    return
  }
  searchList.value = []
  refreshFirst.value = false
  list.value = val
  if (props.modelValue?.length) {
    const i = list.value.findIndex(item => item[props.valueKey] === props.modelValue[0])
    nextList.value = []
    if (typeof i === 'number' && i > -1) {
      firstIndex.value = i
      current.value = list.value[i]
      nextTick(() => {
        nextList.value = val[i][props.childrenKey]
        secondIndex.value = nextList.value.findIndex(item => item[props.valueKey] === props.modelValue[1])
      })
    } else {
      if (list.value?.length) {
        current.value = list.value[0]
        nextTick(() => {
          nextList.value = list.value[0].items
        })
      }
    }
  } else {
    if (current.value) {
      current.value = list.value.find(item => item[props.valueKey] === current.value[props.valueKey])
      if (!current.value) return
      nextList.value = current.value[props.childrenKey]
    }
  }
  nextTick(() => {
    refreshFirst.value = true
  })
}

function clearData() {
  current.value = undefined
  currentInner.value = undefined
  nextList.value = []
  emit('update:modelValue', [])
  emit('change')
}

function hasModelValue() {
  if (props.modelValue?.length) {
    console.log(props.modelValue, 'props.modelValue')
    firstIndex.value = list.value.findIndex(item => {
      return item[props.valueKey] === props.modelValue[0]
    })
    current.value = list.value[firstIndex.value]
    nextList.value = current.value?.[props.childrenKey]
    secondIndex.value = nextList.value?.findIndex(item => {
      return item[props.valueKey] === props.modelValue[1]
    })
    currentInner.value = nextList.value[secondIndex.value]
    nextTick(() => {
      calNextPosi()
    })
  }
}

function setCurrent(item) {
  nextList.value = []
  nextTick(() => {
    current.value = item
    nextList.value = item.items
    calNextPosi()
  })
}

function calNextPosi() {
  if (!firstMenuRef.value) return
  const bound = firstMenuRef.value.getBoundingClientRect()
  nextStyle.value = {
    left: bound.right + 'px',
    top: bound.top + 'px',
  }
}

function setCurrentInner(item) {
  if (item.disabled) return
  currentInner.value = item
  emit('update:modelValue', [current.value, currentInner.value])
  emit('change', [current.value, currentInner.value])
}

function setValueBySearchList(item) {
  if (item.disabled) return
  current.value = item.parent
  currentInner.value = item
  emit('update:modelValue', [current.value, currentInner.value])
  emit('change', [current.value, currentInner.value])
}

defineExpose({
  clearData,
  optionsChange,
  hasModelValue,
})
</script>

<template>
  <div class="cascader-panel-container" :style="style" @click.stop>
    <div v-if="refreshFirst" ref="firstMenuRef" class="cascader-menu">
      <!-- <VirtualScroll :list="list" :height="34" :rowKey="valueKey">
        <template #default="{ item }">
          <div :class="['list-item', { 'active': current?.[props.valueKey] === item[props.valueKey] }]" @click="setCurrent(item)">
            <span class="item-name" :title="item[labelKey]">{{ item[labelKey] }}</span>
            <el-icon><ArrowRight /></el-icon>
          </div>
        </template>
      </VirtualScroll> -->
      <InfiniteList v-if="list?.length" :data="list" :width="'100%'" :height="206" :scrollToIndex="firstIndex" v-slot="{ item }" :itemSize="34">
        <div :class="['list-item', { 'active': current?.[props.valueKey] === item[props.valueKey] }]" @click="setCurrent(item)">
          <span class="item-name" :title="item[labelKey]">{{ item[labelKey] }}</span>
          <el-icon><ArrowRight /></el-icon>
        </div>
      </InfiniteList>
      <div v-else class="empty">
        暂无数据
      </div>
      <!-- <VirtList :list="list" itemKey="valueKey" fixed :buffer="2" :minSize="6">
        <template #default="{ itemData, index }">
          <div class="list-item">
            <span class="item-name" :title="itemData[labelKey]">{{ itemData[labelKey] }}</span>
            <el-icon><ArrowRight /></el-icon>
          </div>
        </template>
      </VirtList> -->
      <!-- <div class="list-item"></div> -->
    </div>
    <div v-if="!!nextList?.length" :style="nextStyle" class="cascader-menu cascader-menu-next">
      <InfiniteList :data="nextList" :width="'100%'" :height="206" :scrollToIndex="secondIndex" v-slot="{ item }" :itemSize="34">
        <div :class="['list-item', { 'active': currentInner?.[props.valueKey] === item[props.valueKey] }, { disabled: item.disabled }]" @click="setCurrentInner(item)">
          <span v-if="item.coding" style="color:var(--themeColor,#17a2b8)"> ({{ item.coding}}) </span>
          <span class="item-name" :title="item[labelKey]">{{ item.name }}</span>
          <span v-if="item.unit" style="color:var(--themeColor,#17a2b8)"> ({{ item.unit}}) </span>
        </div>
      </InfiniteList>
      <!-- <VirtualScroll :list="list" :height="34" :rowKey="valueKey">
        <template #default="{ item }">
          <div :class="['list-item']" @click="setCurrent(item)">
            <span v-if="item.coding" style="color:var(--themeColor,#17a2b8)"> ({{ item.coding}}) </span>
            <span class="item-name" :title="item[labelKey]">{{ item.name }}</span>
            <span v-if="item.unit" style="color:var(--themeColor,#17a2b8)"> ({{ item.unit}}) </span>
          </div>
        </template>
      </VirtualScroll> -->
    </div>

    <div v-if="!!searchValue.value" :style="style" class="cascader-menu cascader-menu-next">
      <InfiniteList v-if="searchList.length" :data="searchList" :width="'100%'" :height="206" v-slot="{ item }" :itemSize="34">
        <div :class="['list-item', { 'active': currentSearchOption?.[props.valueKey] === item[props.valueKey] }, { disabled: item.disabled }]" @click="setValueBySearchList(item)">
          <span v-if="item.coding" style="color:var(--themeColor,#17a2b8)"> ({{ item.coding}}) </span>
          <span class="item-name" :title="item[labelKey]">{{ `${item.parentLabel} / ${item[labelKey]}` }}</span>
          <span v-if="item.unit" style="color:var(--themeColor,#17a2b8)"> ({{ item.unit}}) </span>
        </div>
      </InfiniteList>
      <div v-else class="empty">
        暂无数据
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cascader-panel-container {
  position: fixed;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

  .cascader-menu {
    width: 204px;
    height: 206px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #a8abb2;
      font-size: 14px;
    }

    &.cascader-menu-next {
      position: fixed;
      width: 400px;
    }

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      padding: 0 10px;
      font-size: 14px;
      color: #606266;
      cursor: pointer;

      &.active {
        font-weight: 700;
        color: #409eff;
      }

      .item-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.disabled {
        opacity: .7;
        cursor: not-allowed;
      }
    }
  }
}
</style>
