<template>
  <div ref="virtualCascaderRef" class="virtual-cascader-container" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave" @click.stop="togglePopperVisible(true)">
    <div class="input-box">
      <input v-model="chooseText" class="input" type="text" @input="$event => searchAndInputDebounce($event)" />
      <el-icon
        v-if="inputHover && choosedKey?.length"
        key="clear"
        @click.stop="handleClear"
      >
        <circle-close />
      </el-icon>
      <el-icon
        v-else
        key="arrow-down"
        @click.stop="() => togglePopperVisible(!popperVisible)"
      >
        <arrow-down v-if="!popperVisible" />
        <ArrowUp v-else />
      </el-icon>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, createVNode, render, watch, nextTick } from 'vue'
import { ArrowDown, CircleClose, ArrowUp } from '@element-plus/icons-vue'

import CascaderPanel from './CascaderPanel.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  labelKey: {
    type: String,
    default: 'label'
  },
  valueKey: {
    type: String,
    default: 'value'
  },
  childrenKey: {
    type: String,
    default: 'children'
  },
  options: {
    type: Array,
    default() {
      return []
    }
  },
})
const inputHover = ref(false)
const searchValue = ref(undefined)
const virtualCascaderRef = ref()
const cascaderPanelRef = ref()
let destroyFunc

document.body.addEventListener('click', (e) => {
  searchValue.value !== undefined && calChooseText()
  popperVisible.value && togglePopperVisible(false)
})

const resultOptions = computed(() => {
  if (!searchValue.value) return props.options
  const ol = []
  props.options.forEach((item) => {
    let ocl
    const result = {
      ...item,
    }
    if (item.name.includes(searchValue.value)) {
      ol.push({
        ...result,
      })
    } else if (item && item.name && item.items.length) {
      ocl = item[props.childrenKey].filter(oc => {
        return oc.name.includes(searchValue.value)
      })
      if (ocl.length) {
        const addSubject = item.items.filter(oc => oc[props.valueKey].includes('add')) || []
        result.items = [...addSubject, ...ocl]
        ol.push({
          ...result,
        })
      }
    }
  })
  return ol
})
 
watch(resultOptions, (val, oldVal) => {
  if (searchValue.value === undefined) {
    setTimeout(() => {
      calChooseText()
    })
  }
  cascaderPanelRef.value?.component.exposed.optionsChange(val, oldVal)
})

const choosedKey = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})
const chooseText = ref('')
const popperVisible = ref(false)

calChooseText()

function calChooseText() {
  console.log(props.modelValue);
  if (!props.modelValue?.length) return chooseText.value = ''
  if (!props.options?.length) return chooseText.value = ''
  const first = props.options.find(item => {
    return item[props.valueKey] === props.modelValue[0]
  })
  if (!first) return chooseText.value = ''
  const second = first[props.childrenKey].find(item => {
    return item[props.valueKey] === props.modelValue[1]
  })
  if (!second) return chooseText.value = ''
  chooseText.value = `${first[props.labelKey]}/${second[props.labelKey]}`
}

function handleMouseenter() {
  inputHover.value = true
}
function handleMouseleave() {
  inputHover.value = false
}

function handleClear() {
  chooseText.value = ''
  searchValue.value = undefined
  // emit('update:modelValue', undefined)
  cascaderPanelRef.value?.component.exposed.clearData()
}

const searchAndInputDebounce = debounce(searchAndInput, 200)

function searchAndInput(e) {
  searchValue.value = e.target.value
}
function togglePopperVisible(visible) {
  if (window.togglePopperVisible && window.togglePopperVisible !== togglePopperVisible) window.togglePopperVisible(false)
  visible = visible ?? !popperVisible.value
  if (visible !== popperVisible.value) {
    popperVisible.value = visible
    if (visible) {
      destroyFunc = createFirstMenu()
    } else {
      if (destroyFunc) {
        destroyFunc()
        destroyFunc = null
        setTimeout(() => {
          if (props.modelValue?.length) {
            searchValue.value = undefined
          } else {
            chooseText.value = ''
            searchValue.value = undefined
          }
        })
        
      }
    }
  }
}

function createFirstMenu() {
  const div = document.createElement('div')
  const posi = virtualCascaderRef.value.getBoundingClientRect()
  function getProps() {
    return {
      style: {
        left: posi.left + 'px',
        top: posi.top + posi.height + 10 + 'px',
        'z-index': 10000,
      },
      modelValue: choosedKey.value,
      searchValue: searchValue,
      options: resultOptions,
      labelKey: props.labelKey,
      valueKey: props.valueKey,
      childrenKey: props.childrenKey,
      popperVisible: popperVisible,
      onChange,
      'onUpdate:modelValue'(val) {
        if (val?.length) {
          emit('update:modelValue', [val[0][props.valueKey], val[1][props.valueKey]])
        } else {
          emit('update:modelValue', undefined)
        }
      }
    }
  }
  const vnode = cascaderPanelRef.value = createVNode(CascaderPanel, getProps())
    
  render(vnode, div)
  document.body.appendChild(div)

  window.togglePopperVisible = togglePopperVisible

  return () => {
    render(null, div)
    document.body.removeChild(div)
  }
}

function onChange(data) {
  if (data?.length) {
    chooseText.value = `${data[0][props.labelKey]}/${data[1][props.labelKey]}`
  } else {
    chooseText.value = ''
  }
  searchValue.value = undefined
  togglePopperVisible(false)
}

function debounce(func, temp) {
  let timer
  return function (...arg) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      func(...arg)
    }, temp)
  }
}
</script>

<style lang="scss" scoped>
.virtual-cascader-container {
  width: 100%;

  input {
    outline:none
  }

  .input-box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1px 7px;
    border: 1px solid #b9b9b9;
    cursor: pointer;

    .input {
      display: block;
      flex: 1;
      height: 20px;
      padding: 0;
      font-size: 12px;
      color: #606266;
    }
  }
}
</style>
